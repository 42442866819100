import { useEffect, useState } from 'react'
import { useMyContext } from '../context/context'
import Token from '../utils/Token'

const useWebsocket = modeFullScreen => {
  const { eventId } = useMyContext()
  const [data, setData] = useState(null)
  const [count, setCount] = useState(0)

  useEffect(() => {
    let auth = false
    let ws = null

    console.log('CREATE WEBSOCKET ON', eventId)
    ws = new WebSocket(
      `wss://dkoja9wmfb.execute-api.eu-west-3.amazonaws.com/prod?eventId=${eventId}&token=${
        modeFullScreen ? 'bww7jzu29fs5zfuhi80v' : Token.get()
      }`
    )

    ws.onopen = () => {
      console.log('OPEN')
      auth = true
    }

    ws.onmessage = e => {
      console.log(JSON.parse(e.data).data)
      setData(JSON.parse(e.data).data)
    }

    ws.onclose = e => {
      console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason)
      if (auth && count < 10)
        setTimeout(() => {
          setCount(prev => prev + 1)
        }, 1000)
    }

    return () => {
      auth = false
      ws.close()
      ws = null
    }
  }, [count, eventId, modeFullScreen])

  return [data]
}

export default useWebsocket
