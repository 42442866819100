import { Alert, Button, Form, Input, Modal, Select } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useCreateInvoiceMutation, useGetInvoicesResumeQuery, useGetLastInvoiceQuery, useSetPromoCodeMutation } from '../../api/article.api'
import { useMyContext } from '../../context/context'
import useConf from '../../hooks/useConf'
import { useSteps } from './Steps'

const Resume = () => {
  const { myContext, settings } = useMyContext()
  const { isEnd, articles } = useSteps()
  const [form] = Form.useForm()
  const [form2] = Form.useForm()
  const { articlesActive } = useConf()

  const [modal, setModal] = useState(false)
  const [showPromo, setShowPromo] = useState(false)
  const [error, setError] = useState(false)

  const a = useMemo(() => Object.entries(articles).reduce((prev, [, articleIds]) => [...prev, ...articleIds], []), [articles])
  const { data: resume } = useGetInvoicesResumeQuery({ ...myContext, body: isEnd ? {} : { articles: a } })
  const { data: invoice } = useGetLastInvoiceQuery(myContext)
  const [createInvoice, { isLoading }] = useCreateInvoiceMutation()
  const [setPromoCode, { isLoading: isLoading1, data }] = useSetPromoCodeMutation()

  useEffect(() => {
    if (data?.noCode) {
      setError(true)
      setTimeout(() => {
        setError(false)
        setShowPromo(false)
      }, 2000)
    }
  }, [data])

  useEffect(() => {
    if (resume) {
      form.setFieldsValue({ code: resume?.promoCode?.code || '' })
    }
  }, [form, resume])

  useEffect(() => {
    if (invoice && form2) {
      form2.setFieldsValue(invoice)
    }
  }, [form2, invoice])

  if (!resume?.canPay) {
    return null
  }

  return (
    <>
      <Modal
        title="Coordonnées de facturation"
        visible={modal}
        onCancel={() => setModal(false)}
        footer={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={() => setModal(false)}>Annuler</Button>
            <Button
              type="primary"
              onClick={() => {
                form2.submit()
              }}
              loading={isLoading}
            >
              Payer
            </Button>
          </div>
        }
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '100%', maxWidth: 600 }}>
            <Form
              form={form2}
              onFinish={async values => {
                const { session } = await createInvoice({
                  ...myContext,
                  body: { referer: window.location.href, bill: values.bill },
                }).unwrap()
                window.location.href = session.url
              }}
            >
              <Form.Item name={['bill', 'type']} rules={[{ required: true, message: 'Ce champ est obligatoire' }]}>
                <Select
                  placeholder="Veuillez sélectionner"
                  options={[
                    { label: 'Société en France (société)', value: 1 },
                    { label: 'Société en Europe (hors France)', value: 2 },
                    { label: 'Société hors Europe', value: 3 },
                    { label: 'Individuel (France ou étranger)', value: 4 },
                    { label: 'Collectivité Territoriale', value: 5 },
                    { label: 'Etablissement Public', value: 6 },
                  ]}
                />
              </Form.Item>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => {
                  const types = getFieldValue(['bill', 'type'])

                  return (
                    [2, 3].includes(types) && (
                      <>
                        <Form.Item
                          name={['bill', 'intra_communautaire']}
                          label="N° TVA INTRA COMMUNAUTAIRE"
                          rules={[{ required: true, message: 'Ce champ est obligatoire' }]}
                          preserve={false}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name={['bill', 'country']}
                          label="Pays"
                          rules={[{ required: true, message: 'Ce champ est obligatoire' }]}
                          preserve={false}
                        >
                          <Input />
                        </Form.Item>
                      </>
                    )
                  )
                }}
              </Form.Item>
              <Form.Item
                label="Nom/Raison Sociale"
                name={['bill', 'social_reason']}
                rules={[{ required: true, message: 'Ce champ est obligatoire' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item label="Adresse" name={['bill', 'address']} rules={[{ required: true, message: 'Ce champ est obligatoire' }]}>
                <Input />
              </Form.Item>
              <Form.Item label="Complément d'adresse" name={['bill', 'address_more']}>
                <Input />
              </Form.Item>
              <Form.Item
                label="Code postal"
                name={['bill', 'postal_code']}
                rules={[{ required: true, message: 'Ce champ est obligatoire' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item label="Ville" name={['bill', 'city']} rules={[{ required: true, message: 'Ce champ est obligatoire' }]}>
                <Input />
              </Form.Item>
              <Form.Item style={{ fontSize: 20, fontWeight: 700, marginBottom: -16 }} shouldUpdate>
                {({ getFieldValue }) => {
                  const type = Number(getFieldValue(['bill', 'type']))
                  if ([1, 4, 5, 6].includes(type)) {
                    return `TOTAL : ${resume.priceTvaTotalToPay.toFixed(2)} € T.T.C`
                  }

                  return `TOTAL : ${resume.priceTotalToPay.toFixed(2)} € H.T.`
                }}
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
      <div style={{ marginBottom: 16, display: 'flex', flexDirection: 'column', gap: 4, alignItems: 'end' }}>
        <div>Total de votre réservation : {(resume?.priceTotal || 0).toFixed(2)} € H.T.</div>
        {resume?.priceTotalPaid > 0 && <div>Total déjà payé : -{resume.priceTotalPaid.toFixed(2)} € H.T.</div>}
        {resume?.promoCode && <div>Code promo : -{resume.promoCode.price.toFixed(2)} € H.T.</div>}
        <div>Total : {(resume?.priceTotalToPay || 0).toFixed(2)} € H.T.</div>
        <div style={{ fontWeight: 700 }}>Soit {(resume?.priceTvaTotalToPay || 0).toFixed(2)} € T.T.C</div>
        {showPromo ? (
          <>
            <Form
              form={form}
              onFinish={async v => {
                if (resume?.promoCode?.code === v.code) {
                  setShowPromo(false)
                  return
                }

                const result = await setPromoCode({ ...myContext, ...v }).unwrap()
                if (result?.noCode) {
                  form.setFieldsValue({ code: resume?.promoCode?.code || '' })
                } else {
                  setShowPromo(false)
                }
              }}
              style={{ display: 'flex', gap: 8 }}
            >
              <Form.Item name="code" noStyle>
                <Input placeholder="Saisir un code promo" />
              </Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading1}>
                Activer le code
              </Button>
            </Form>
            {error && <Alert message="Ce code n'existe pas ou a déjà été utilisé" type="error" />}
          </>
        ) : (
          <div
            style={{
              textDecoration: 'underline',
              cursor: 'pointer',
              marginBottom: 8,
              display: settings?.FO_MEETING_CONFIRMATION_HIDE_PAY ? 'none' : 'visible',
            }}
            onClick={() => setShowPromo(true)}
          >
            J'ai un code promo
          </div>
        )}
        {isEnd && resume.canPay && !settings?.FO_MEETING_CONFIRMATION_HIDE_PAY && (
          <Button type="primary" onClick={() => setModal(true)}>
            {settings?.FO_MEETING_ARTICLES_TO_PAY_LABEL || 'Payer'}
          </Button>
        )}
      </div>
    </>
  )
}

export default Resume
