import { changeAntdTheme } from 'mini-dynamic-antd-theme'
import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom'
import { ContextProvider, useMyContext } from './context/context'
import Conferences from './screens/Conferences/Conferences'
import CreateAnonymous from './screens/CreateAnonymous'
import Badge from './screens/DownloadBadge/badge'
import Home from './screens/Home'
import Leaderboard from './screens/Leaderboard'
import Login from './screens/Login'
import PhotoViewer from './screens/PhotoViewer'
import Photos from './screens/Photos'
import Replay from './screens/Replay'
import SatisfactionForm from './screens/SatisfactionForm'
import Contacts from './screens/contacts/Contacts'
import ContactsScan from './screens/contacts/ContactsScan'
import CreateAccount from './screens/createAccount'
import Live from './screens/live/live'
import ModeOrga from './screens/live/modeOrga'
import ModeSpeaker from './screens/live/modeSpeaker'
import LoginBadge from './screens/loginBadge'
import Message from './screens/message'
import PasswordNew from './screens/newPassword'
import PasswordForgot from './screens/passwordForgot'
import Sections from './screens/sections'
import StatsScreen from './screens/stats'
import StepsContextProvider from './screens/steps/Steps'
import ROUTES from './services/routes'
import Facebook from './shared/facebook'
import Footer from './shared/footer'
import GoogleAnalytics from './shared/googleAnalytics'
import Header from './shared/header'
import PrivateRoute from './shared/privateRoute'
import PublicRoute from './shared/publicRoute'

export const Loading = () => {
  const { settings } = useMyContext()
  const loading = useSelector(state => state.loading.is)

  return (
    <div style={{ backgroundColor: settings?.bgPrimaryColor || '#FFFFFF' }} className={`pageloader ${loading && 'is-active'}`}>
      <span className="title">Chargement</span>
    </div>
  )
}

const AppRoutes = () => {
  const { path: basePath } = useRouteMatch()
  const { settings, domainId, eventId, isFetchingUser } = useMyContext()

  const routes = useMemo(
    () => [
      {
        path: ROUTES.HOME,
        component: Home,
        isPrivate: true,
      },
      { path: ROUTES.LOGIN, component: Login },
      { path: ROUTES.LOGIN_WITH_BADGE, component: LoginBadge },
      {
        path: ROUTES.LIVE,
        component: Live,
        isPrivate: true,
      },
      {
        path: ROUTES.LIVE_ORGA,
        component: ModeOrga,
      },
      {
        path: ROUTES.LIVE_SPEAKER,
        component: ModeSpeaker,
      },
      {
        path: ROUTES.PASSWORD_FORGOT,
        component: PasswordForgot,
      },
      {
        path: ROUTES.PASSWORD_NEW,
        component: PasswordNew,
        isPrivate: true,
      },
      {
        path: ROUTES.CREATE_ACCOUNT,
        component: CreateAccount,
      },
      {
        path: ROUTES.EVENTS_INSCRIPTION,
        component: StepsContextProvider,
        isPrivate: true,
      },
      {
        path: ROUTES.MESSAGE,
        component: Message,
        isPrivate: true,
      },
      {
        path: ROUTES.REPLAY,
        component: Replay,
        isPrivate: true,
      },
      {
        path: ROUTES.SATISFACTION,
        component: SatisfactionForm,
        isPrivate: true,
      },
      {
        path: ROUTES.PHOTOS,
        component: Photos,
      },
      {
        path: ROUTES.PHOTOS_VIEWER,
        component: PhotoViewer,
        isPrivate: true,
      },
      {
        path: ROUTES.LEADERBOARD,
        component: Leaderboard,
      },
      {
        path: ROUTES.BADGE_DOWNLOAD,
        component: Badge,
        isPrivate: true,
      },
      {
        path: '/users/:userId/conferences',
        component: Conferences,
        isPrivate: true,
      },
      {
        path: '/stats',
        component: StatsScreen,
        isPrivate: settings?.FO_MEETING_STATS_IS_PRIVATE,
      },
      {
        path: '/users/new',
        component: CreateAnonymous,
      },
      {
        path: '/contacts/new',
        component: ContactsScan,
        isPrivate: true,
      },
      {
        path: '/contacts',
        component: Contacts,
        isPrivate: true,
      },

      {
        path: '/sections/:sectionId',
        component: Sections,
        isPrivate: true,
      },
    ],
    [settings]
  )

  useEffect(() => {
    if (!settings || !domainId || !eventId) return
    document.title = settings.title || 'Inscription'

    const favicon = document.getElementById('favicon')
    if (favicon) favicon.href = `https://gayakoa-files-eu-west-1-public.s3.eu-west-1.amazonaws.com/${settings.favicon}`

    const root = document.getElementById('root')
    root.style.backgroundColor = settings.backgroundColor
    if (settings.bgPrimaryColor) changeAntdTheme(settings.bgPrimaryColor)
  }, [domainId, eventId, settings])

  return (
    <>
      <div
        className="container"
        style={{
          background: settings?.containerColor || '#FFFFFF',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Loading />
          <Header />
          <div style={{ width: '100%', padding: 20 }}>
            {isFetchingUser ? (
              <Loading />
            ) : (
              <Switch>
                {routes.map(({ path, isPrivate, component }) => {
                  return isPrivate ? (
                    <PrivateRoute key={basePath + path} path={basePath + path} component={component} exact />
                  ) : (
                    <PublicRoute key={basePath + path} path={basePath + path} component={component} exact />
                  )
                })}
                <Route>
                  <Login />
                </Route>
              </Switch>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

const AppRouter = () => {
  const location = useLocation()

  useEffect(() => {
    const search = new URLSearchParams(location.search)

    const canal = search.get('canal')
    if (canal) {
      localStorage.setItem('canal', canal)
    }
  }, [location.search])

  return (
    <Route path="/:context">
      <ContextProvider>
        <AppRoutes />
        <Facebook />
        <GoogleAnalytics />
        <Loading />
      </ContextProvider>
    </Route>
  )
}

export default AppRouter
